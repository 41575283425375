import React from "react"
import styled from "styled-components"
import PuffLoader from "react-spinners/PuffLoader"

const StyledSubmitLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  border-radius: var(--card-border-radius);
  display: flex;
  justify-content: center;
  align-items: center;

  .loader-container {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`

const SubmitLayer = ({ isSubmitting }) => {
  return (
    <StyledSubmitLayer>
      <div className="loader-container">
        <PuffLoader color={"#ffffff"} loading={isSubmitting} size={100} />
      </div>
    </StyledSubmitLayer>
  )
}

export default SubmitLayer
