import styled, { css } from "styled-components"
import respond from "../styles/abstracts/mediaqueries"
import backgroundImage from "../images/form-background.webp"

const StyledForm = styled.section`
  background-image: url(${backgroundImage});
  background-size: cover;
  padding: 6rem;
  border-radius: var(--card-border-radius);
  color: var(--white);
  font-size: 1.8rem;
  box-shadow: 15px 25px 37px -5px rgba(0, 0, 0, 0.31);
  box-sizing: border-box;
  clip-path: polygon(0 0, 100% 0, 100% 83%, 50% 100%, 0 83%);
  padding-bottom: 23rem;
  //mix-blend-mode: soft-light;
  position: relative;

  ${respond(
    "ipad-pro-11-port",
    css`
      clip-path: polygon(0 0, 100% 0, 100% 90%, 50% 100%, 0 90%);
    `
  )}
  ${respond(
    "iphone-5-land",
    css`
      border-radius: 20px;
    `
  )}
  ${respond(
    "phone-port",
    css`
      clip-path: none;
      padding: 5rem 0;
      //padding-bottom: 5rem;
    `
  )}

  .subtitle, .title {
    font-size: 3.5rem;
    width: 60%;
    margin: 0 auto;
    text-align: center;

    ${respond(
      "laptop",
      css`
        width: 80%;
      `
    )}

    ${respond(
      "tab-port",
      css`
        width: 100%;
        font-size: 3rem;
      `
    )}
    
    ${respond(
      "phone-land",
      css`
        width: 90%;
        font-size: 3rem;
      `
    )}
  }

  .title {
    ${respond(
      "phone-port",
      css`
        width: 80%;
        font-size: 2rem;
      `
    )}
    ${respond(
      "small-phone",
      css`
        width: 90%;
      `
    )}
  }

  .subtitle {
    ${respond(
      "phone-port",
      css`
        width: 80%;
        font-size: 1.8rem;
        margin-top: 2rem;
      `
    )}
  }

  .form {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;

    ${respond(
      "tab-port",
      css`
        flex-direction: column;
        gap: 0;
      `
    )}
    ${respond(
      "phone-port",
      css`
        margin-top: 1rem;
      `
    )}
  }

  h3 {
    color: var(--white);
  }

  .input-container {
    width: ${({ fullInputWidth }) =>
      fullInputWidth ? css`100%` : css`calc(40% - 2rem)`};
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 3rem;
    }

    ${respond(
      "phone-land",
      css`
        width: 100%;
      `
    )}

    ${respond(
      "tab-port",
      css`
        width: 90%;
        margin: 0 auto;
        gap: 0;
      `
    )}
    
    ${respond(
      "phone-port",
      css`
        width: 85%;
      `
    )}
  }
  .form {
    width: 100% !important;
  }
  .checkbox {
    flex-direction: row;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .checkboxes-container {
    width: 95%;

    ${respond(
      "tab-port",
      css`
        margin-left: 0;
      `
    )}
  }

  .checkbox {
    margin-right: 2rem;
    transform: scale(1.8);

    ${respond(
      "tab-port",
      css`
        padding: 0.8rem;
      `
    )}
  }

  label {
    margin-bottom: 0.8rem;

    ${respond(
      "phone-port",
      css`
        font-size: 1.6rem;
      `
    )}
  }

  .text-input,
  textarea {
    height: 5rem;
    border-radius: 10px;
    outline: none;
    border: none;
    background-color: rgba($color-secondary, 0.05);
    padding-left: 2rem;
    font-size: 2rem;

    &:active,
    &:focus {
      border: 1px solid $color-secondary;
      box-shadow: 10px 20px 45px -10px rgba($color-secondary, 0.2);
      background-color: $white;
    }
  }

  textarea {
    height: 30rem;
    padding-top: 2rem;
    padding-right: 2rem;
    line-height: 1.6;
  }

  .submit-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .submit {
    font-family: "Work Sans", sans-serif;
    color: var(--white);
    border: none;
    outline: none;
    width: 35rem;
    height: 8rem;
    border-radius: var(--card-border-radius);
    background-color: #17162d;
    font-size: 2rem;
    letter-spacing: 1.8px;
    transition: all 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    ${respond(
      "tab-port",
      css`
        margin: 4rem auto 0 auto;
      `
    )}
    ${respond(
      "phone-port",
      css`
        width: 90%;
        margin: 0 auto 0 auto;
      `
    )}
    ${respond(
      "iphone-5",
      css`
        width: 100%;
      `
    )}

    &:hover {
      box-shadow: 10px 20px 45px -10px rgba($color-secondary, 0.15);
      cursor: pointer;
      transform: scale(1.05);
    }
  }

  .full-width {
    width: calc(80% - 2rem);
    margin: 0 auto;
  }

  .message {
    text-align: center;
    font-size: 3rem;
    font-family: $body-font;

    ${respond(
      "phone-port",
      css`
        width: 80%;
        margin: 4rem auto;
      `
    )}

    &--error {
      color: darkred;
    }

    &--success {
      color: darkgreen;
    }
  }

  .checkbox-container {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: 1fr;
    align-items: center;
  }
`

export default StyledForm
